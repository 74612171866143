import Vue from 'vue'
import VueRouter from 'vue-router'
// import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  // routes
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/m/home',
      component: () => import('@/views/mobile/Home.vue'),
    },
    {
      path: '/about',
      component: () => import('@/views/About.vue'),
    },
    {
      path: '/contact',
      component: () => import('@/views/Contact.vue'),
    },
    {
      path: '/privacy',
      component: () => import('@/views/Privacy.vue'),
    },
    {
      path: '/terms',
      component: () => import('@/views/Terms.vue'),
    },
    {
      path: '/price',
      component: () => import('@/views/Price.vue'),
    },
  ]
})

router.beforeEach ((to, from, next) => {
  // 移动端适配
  // console.log(window.innerWidth, to, from)
  if (window.innerWidth < 767) {
    if (to.path === '/home') {
      next({ path: '/m/home' })
    } else {
      next()
    }
  } else {
    if (to.path === '/m/home') {
      next({ path: '/home' })
    } else {
      next()
    }
  }
})

export default router
