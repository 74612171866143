<template>
  <div id="app">
    <div class="top-header flex-between flex-ai-center" v-if="$route.path !== '/terms' && $route.path !== '/privacy'">
      <div class="flex-center">
        <img width="178" src="./assets/logo.png" alt="logo" />
      </div>
      <div class="menus flex-row">
        <div class="pointer title-item" @click="_navTo('/home')">首页</div>
        <div class="pointer title-item" @click="_navTo('/about')">关于我们</div>
        <div class="pointer title-item" @click="_navTo('/contact')">联系我们</div>
        <!-- <div class="title-item" @click="_navTo('/privacy')">Privacy</div> -->
        <!-- <div class="title-item" @click="_navTo('/terms')">Terms</div> -->
      </div>
    </div>
    <router-view></router-view>
    <div class="footer flex-center" v-if="$route.path !== '/terms' && $route.path !== '/privacy'">
      <div class="info flex-row">
        <div class="terms">
          <div class="pointer" @click="$route.path !== '/privacy' && $router.push('/privacy')">隐私政策</div>
          <div class="pointer" @click="$route.path !== '/terms' && $router.push('/terms')">用户协议</div>
        </div>
        <div class="icp">
          <a href="https://beian.miit.gov.cn/">桂ICP备2022004760号</a>
        </div>
        <div class="icp">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010302002945"><img src="./assets/secp.png" style="float:left;"/><p style="float:left;margin: 0px 0px 0px 5px; ">桂公网安备 45010302002945号</p></a>
        </div>
        <div>Copyright © 2023 广西乐开科技有限公司</div>
      </div>
      <div class="report">
        <a href="https://www.12377.cn/">中央网信办违法和不良信息举报中心</a>
        <a href="http://www.gxjubao.org.cn/">广西互联网违法和不良信息举报中心</a>
        <a href="http://www.nnjbpy.org.cn/">南宁市互联网举报·辟谣平台</a>
      </div>
      <div class="report">
        <a href="javaScript:void(0);">举报电话：13819100070 &nbsp;&nbsp;邮箱：gofiredev@adpanshi.com</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      
    } 
  },
  mounted () {
    // console.log('---:', this.userInfo)
  },
  methods: {
    _navTo (r) {
      if (this.$route.path === r) return
      this.$router.push(r)
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  /* background-color: #272734; */
}
@media screen and (max-width: 767px) {
  .menus {
    display: none;
  }

  .menu-icon {
    display: block;
    /* background-color: #030303; */
  }

  .top-header {
    height: 4.5rem;
    padding: 8px 15px;
    /* background: #000; */
  }
  .footer {
    display: flex;
    flex-direction: column;
  }
  .terms {
    display: flex;
    flex-direction: row;
  }
  .terms div {
    margin: 0 2px;
  }
  .terms>:first-child::after {
    content: " |";
  }
}

@media screen and (min-width: 768px) {
  .top-header {
    height: 6rem;
    padding: 25px 50px;
  }

  .menu-icon {
    display: none;
  }

  .menus {
    display: flex;
    z-index: inherit;
  }
  .footer {
    display: flex;
    flex-direction: column;
  }
  .footer div {
    margin-left: 25px;
  }
  .terms {
    display: flex;
  }
}

.top-header {
  position: fixed;
  top: 0;
  width: 100%;
  /* height: 100px; */
  background: #FFF;
  z-index: 1000;
}
.title-item {
  color: #34343B;
  margin-right: 40px;
}
.title-item:hover {
  text-decoration: underline;
  text-decoration-color: #FE6600;
  text-underline-offset: 10px;
  text-decoration-thickness: 3px;
}
.footer {
  width: 100%;
  height: 130px;
  font-size: 18px;
  color: #FE6600;
  background-color: #030303;
}

.icp a, .report a {
  color: #FFFFFF;
  text-decoration: none;
}
.report {
  font-size: 12px;
  color: #F0F0F0;
  margin-top: 5px;
}
.report a {
  margin-right: 5px;
}
</style>
