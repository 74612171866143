import Vue from 'vue'
import App from './App'
import router from './router'
import {Carousel, CarouselItem, Table, TableColumn} from 'element-ui'
import './style/public.css'
import './style/element-variables.scss'
import { formatDate, formatTime } from './util'


Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Table)
Vue.use(TableColumn)

Vue.config.productionTip = false

Vue.prototype.formatTime = formatTime
// 格式化标题中的时间，opt中可能包含startTime和endTime
Vue.prototype.formatTitleDate = opt => {
  if (!opt.startTime) {
    // 当日
    return formatDate(new Date(), 'yyyy-MM-dd') + ' '
  } else {
    let startDate = opt.startTime.split(' ')[0]
    let endDate = opt.endTime.split(' ')[0]
    if (startDate === endDate) {
      return startDate + ' '
    } else {
      return startDate + '至' + endDate + ' '
    }
  }
}
// 时间戳转为日期
Vue.prototype.formatTimestamp = (ts, fmt) => {
  return formatDate(new Date(ts), fmt)
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
