// 日期格式化
export function formatDate (date, fmt) {
  date = date || new Date()
  fmt = fmt || 'yyyy-MM-dd HH:mm:ss'
  var o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "H+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "S+": date.getMilliseconds()
  };
  //因为date.getFullYear()出来的结果是number类型的,所以为了让结果变成字符串型，下面有两种方法：
  if (/(y+)/.test(fmt)) {
    //第一种：利用字符串连接符“+”给date.getFullYear()+""，加一个空字符串便可以将number类型转换成字符串。
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      //第二种：使用String()类型进行强制数据类型转换String(date.getFullYear())，这种更容易理解。
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(String(o[k]).length)));
    }
  }
  return fmt
}
export function formatTime (seconds) {
    if (!seconds) {
      return '00:00:00'
    }
    let h = Math.floor(seconds / 3600)
    let m = Math.floor((seconds - h * 3600) / 60)
    let s = seconds - h * 3600 - m * 60
    // let result = ''
    // if (h < 1) {
    //   result = '00:' + m + ':' + s
    // } else {
    //   result = h + ':' + m + ':' + s
    // }
    if (h < 10) {
      h = '0' + h + ''
    }
    if (m < 10) {
      m = '0' + m + ''
    }
    if (s < 10) {
      s = '0' + s + ''
    }
    return h + ':' + m + ':' + s
}

export const download = (content, fileName) => {
  var link = document.createElement('a')
  // 设置下载的文件名
  link.download = fileName
  link.target = '_blank'
  link.style.display = 'none'
  // 设置下载路径
  link.href = content
  document.body.appendChild(link)
  // 触发点击
  link.click()
  // 移除节点
  document.body.removeChild(link)
}

